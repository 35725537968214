import React, { Component, Fragment } from 'react'
//import a from 'gatsby-a'
import Logo from '../../assets/mpoi-logo.svg'
import LogoFr from '../../assets/mpoi-logo-fr.svg'
import { menu } from '../../messages.js'
import debounce from '../../utils/debounce'
import './header.scss'

const activeStyle = {
    borderBottom: '7px solid #27fb6b'
}

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scrolled: false,
            showMenu: false
        }
    }

    componentDidMount = () => {
        //window.addEventListener('scroll', this.scrollCheck)
    }
    componentWillUnmount = () => {
        // window.removeEventListener('scroll', this.scrollCheck)
    }

    scrollCheck = () => {
        const windowHeight = window.innerHeight
        const current = window.scrollY || window.pageYOffset
        if (current >= 20 || !this.props.isHomepage) {
            if (this.state.scrolled === true) {
                return
            }
            this.setState({
                scrolled: true
            })
        } else {
            if (this.state.scrolled === false) {
                return
            }
            this.setState({
                scrolled: false
            })
        }
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    closeMenu = () => {
        if (this.state.showMenu) {
            this.setState({
                showMenu: false
            })
        }
    }

    render = () => {
        const localizedMenu = menu[this.props.lang]
        const localizedLogo = this.props.lang === 'en' ? Logo : LogoFr
        const { langToggleText, langToggleLink } = localizedMenu
        return (
            <Fragment>
                <div className='header'>
                    <div className={this.state.showMenu ? 'shown' : ''}>
                        <div className='menuIcon' onClick={this.toggleMenu}>
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <div className='mobile-mini-menu'>
                            <a
                                href='/#home'
                                onClick={this.closeMenu}
                                className='logo'
                            >
                                <img src={localizedLogo} />
                            </a>
                            <a
                                href={`/#${localizedMenu.getIt.id}`}
                                className='btn outline'
                            >
                                {localizedMenu.getIt.title}
                            </a>
                        </div>
                        <div className='menu'>
                            <div className='menu-2'>
                                <a
                                    onClick={this.toggleMenu}
                                    href={`/#${localizedMenu.faq.id}`}
                                >
                                    {localizedMenu.faq.title}
                                </a>
                                <a
                                    onClick={this.toggleMenu}
                                    href={`/#${localizedMenu.contact.id}`}
                                >
                                    {localizedMenu.contact.title}
                                </a>
                                <div className='lang-toggle'>
                                    <a href={langToggleLink}>
                                        {langToggleText}
                                    </a>
                                </div>
                            </div>
                            <div className='menu-1'>
                                <a
                                    href='/#home'
                                    onClick={this.closeMenu}
                                    className='logo'
                                >
                                    <img src={localizedLogo} />
                                </a>
                                <a
                                    onClick={this.toggleMenu}
                                    href={`/#${localizedMenu.about.id}`}
                                >
                                    {localizedMenu.about.title}
                                </a>
                                <a
                                    href={`/#${localizedMenu.how.id}`}
                                    onClick={this.toggleMenu}
                                >
                                    {localizedMenu.how.title}
                                </a>
                                <a
                                    onClick={this.toggleMenu}
                                    href={`/#${localizedMenu.wallets.id}`}
                                >
                                    {localizedMenu.wallets.title}
                                </a>
                                <a
                                    onClick={this.toggleMenu}
                                    href={`/#${localizedMenu.privacy.id}`}
                                >
                                    {localizedMenu.privacy.title}
                                </a>

                                <a
                                    href={`/#${localizedMenu.getIt.id}`}
                                    className='btn outline'
                                    onClick={this.toggleMenu}
                                >
                                    {localizedMenu.getIt.title}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'header-height'} />
            </Fragment>
        )
    }
}

export default Header
